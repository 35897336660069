
.analyticsCardContainer {
  overflow-y: scroll;
  /* height: 90vh; */
  height: calc(100vh - 186px);
}

::-webkit-scrollbar {
  width: 4px;
}

.heading {
  display:flex;
  align-items:center;
  margin: 10px;
  color: #000;
  font-family: Acherus Grotesque;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  padding-top: 1px;
  min-height:60px;
}

.allCardsContainer {
  /* margin-bottom: 3px; */
  position: relative;
}
.allCardsContainer input{
  max-width: 100%;
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  /* background-color: #6b768a1c; */
  /* margin: 10px 0; */
  /* color: #2c2c2c; */
  color: var(--bg-text, #2C2C2C);

/* font-family: Acherus Grotesque; */
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 22px;
border-radius: 5px;
background: rgba(107, 118, 138, 0.05);
}
.allCardsContainer button {
  padding: 10px;
  border: none;
  outline: none;
  background-color: #6b768a1c;
  margin: 10px 0;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}
.allCardsContainer button:hover {
  background-color: #6b768a4a;
}

.cardContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* margin-left: 3%; */
  /* border-radius: 8px; */
  border-bottom: 2px solid #eaeaea;
  background: #fff;
  padding-left: 1rem;
  padding-top: 1rem;
  /* margin-bottom: 10px; */
  /* margin-top: 4.5%; */
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.7s;
  cursor: pointer;
  
}

.cardContainer * {
  backface-visibility: hidden;
}

.styleName {
  color: var(--main-bulma, rgb(132, 132, 244));
  font-family: Acherus Grotesque;
  height: 10%;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

.styleContent {
  color: var(--main-bulma, #000);
  font-family: Acherus Grotesque;
  height: 40%;
  width: 95%;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  padding-top: 15px;
}

.creationMainContainer {
  display: flex;
  margin-top: 8%;
  height: 25%;
  width: 90%;
}

.creatednameContainer {
  width: 50%;
  color: var(--bg-text, #2c2c2c);
  font-family: Acherus Grotesque;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  opacity: 0.9;
}

.creationHeading {
  color: var(--bg-text, #2c2c2c);
  font-family: Acherus Grotesque;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  margin-bottom: 3px;
  opacity: 0.7;
  /* 140% */
}

.creationName {
  color: var(--bg-text, #2c2c2c);
  font-family: Acherus Grotesque;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
}

.creationCompany {
  color: var(--bg-text, #2c2c2c);
  font-family: Acherus Grotesque;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 180% */
}

.createdtimeContainer {
  width: 50%;
  color: var(--bg-text, #2c2c2c);
  font-family: Acherus Grotesque;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}
.border-blue{
  background-color: #DFF7FF;
  border-left: 5px solid #88B0FF;
  font-weight: bold;
}


.border-blue1 {
  /* border: 2px solid #cfe2ff !important; */
  font-weight: bold;
}
.border-red1 {
  border: 2px solid rgb(251, 184, 184) !important;
  font-weight: bold;
}

.scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #ccc;
  height: 10px;
}

.scrollbar::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  height: 10px;
}
.heading {
  margin: 10px;
  color: #000;
  font-family: Acherus Grotesque;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  padding-top: 1px;
  display :flex;
  justify-content: space-between;
  position: relative;
}

/* addAnalyticStylesBtn styling  */

.addAnalyticStylesBtn{
    width: 28px;
    height: 28px;
    border-radius: 50%;
    color: white;
    background-color: #003399;;
    font-size: xx-large;
    border:0px;
}
.addFormContainer{
  width: 95%;
  height: 50%;
  /* margin-left: 30px; */
  padding-left:1rem;

}
.addFormFooter{
  display: flex;
  justify-content: space-between;
}


.date{
  margin-top: 20px
}


.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: #003399;;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }
  #loaderContainer{
    display:flex;
    justify-content: center;
    padding-top: 30px;

  }

  /* @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  }  */
  /* .analyticsCardContainer form{
    
justify-content: center;
  } */
  .aqformGroup{
    margin-bottom:20px;
  }
  
  .cardBack {
    position: absolute;
    width: 100%;
    height: 92%;
    transform: rotateY(180deg);
    backface-visibility: hidden;
    width: 92%;
    /* margin-left: 3%; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .flipped {
    background-color: #ffff;
    border-left: #ffff;
    transform: rotateY(180deg);
  }
  
  
.searchbar1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #dedede;

  height: var(--height-h-10, 40px);

  flex: 1;
  margin-left: 12px;
  margin-right: 5px;
}

.InferredBlock{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border: 2px solid #cfe2ff;
    border-radius:4px;
    padding-right: 5px;
    /* flex: 1; */
    /* padding: 10px; */
    /* margin-left: 19px; */
    /* margin-right: 5px; */
    height: var(--height-h-10, 30px);
}

.animated-searchbar {
  animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
  from {
    transform: translateX(16%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.sidepanel-bottom-part1{
 position: absolute;
 /* width: 100%; */
 top: 87%;
 /* float: right; */
 left: 80%;
 cursor: pointer;
 display: flex;
 /* justify-content: flex-end; */
 margin-right: 1rem;
}

.companyName{
  color: #2C2C2C;
font-family: Acherus Grotesque;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 18px;
}