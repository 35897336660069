@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

.platform-setup-form {
  height: 100%;
  width: 100%;
  max-width: 700px;
  font-family: "Roboto", sans-serif;
}

.input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-family: "Roboto", sans-serif;
  gap: 8px;
  padding: 16px 0;
}

.input-label {
  color: #26282c;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-right: 10px;
}

.form-control {
  width: 100%;
  padding: 10px 5px;
  border-radius: 5px;
  background-color: rgba(107, 118, 138, 0.05);
  border: none;
}

.form-control:focus {
  outline: none;
}

input[type="radio"] {
  width: 15px;
  height: 15px;
}
