* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.container1 {
    width: 74%;
    height: auto;
    position:relative;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    overflow: scroll;
    margin-left: 22%;
    margin-top: 2%;
    padding-bottom: 5px;

    .parent {
        /* width: 90%; */
        height: 33px;
        
        /* padding-left: 2px;
        padding-right: 15px; */
        padding:10px;
        margin-top: 12px;
        cursor: pointer;
        
        border: 1px solid #2c60f5;
        border-radius: 10px;

        background-color: white;


        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        gap: 10px;

        .left {
            width: 70%;

            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: row;

            span {
                width: 40px;
                height: 40px;

                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                background-color: #0376bf;
                color: white;
                border-radius: 999px;
                cursor: pointer;
            }

            p {
                margin-left: 10px;
                font-size: 1rem !important;
            }
        }

        .right {
            width: 30%;

            display: flex;
            justify-content: flex-end;
            align-items: center;

            img {
                margin: 0;
                padding: 0;

                &:first-child {
                    width: 30px;
                    margin-right: 5px;

                    border-radius: 10px;
                    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.10);
                }

                &:last-child {
                    width: 20px;
                    height: 20px;

                    border: 1px solid rgb(255, 0, 0);
                    border-radius: 999px;
                }

                &:nth-last-child(2) {
                    width: 20px;
                    height: 20px;

                    border: 1px solid #0376bf;
                    border-radius: 999px;
                    margin-right: 5px;
                }
            }
        }
    }

    .childContainer {
        max-width: 100rem;
        min-width: min-content;
        height: min-content;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        margin-left: 30px;
        overflow: scroll;
        padding-right: 5px;
        position: relative;

        &::before {
            content: "";
            border: 1px solid #e0e0e0;
            position: absolute;
            width: 0px;
            border-bottom-left-radius: 999px;
            height: calc(100% - 30px);
            left: 0px;
            z-index: 0;
            margin-bottom: 19px;
        }

        & div {
            width: 100%;

            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            gap: 2px;
            height: min-content;

            & section {
                width: 100%;
                height: min-content;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-direction: row;

                & img {
                    width: 20px;
                    margin-right: 5px;
                }

                .arrow {
                    margin-bottom: 20px;
                    width: 30px;
                }

                .child {
                    
                    width: 50%;
                    height: 50px;
                    
                    margin-top: 10px;
                    
                    position: relative;
                    
                    border: 1px solid #d8d8d8;
                    background-color: #f9f9f9;
                    border-radius: 30px;

                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-direction: row;

                    padding-left: 2px;

                    span {
                        display: flex;
                        width: 40px;
                        height: 40px;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        background-color: #0376bf;
                        color: white;
                        border-radius: 999px;
                        cursor: pointer;
                    }

                    p {
                        margin-left: 10px;
                        font-size: 1rem;
                    }

                    img {
                        position: absolute;
                        right: 10px;
                        top: 14px;
                    }
                }
            }

            .grandChildContainer {
                width: 100%;
                min-width: min-content;
                display: flex;
                flex-direction: column;
                gap: 10px;
                overflow: scroll;
                padding-left: 50px;

                & div {
                    position: relative;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-direction: row;
                    gap: 2px;

                    .arrow {
                        margin-bottom: 10px;
                        width: 20px;
                        margin-right: 5px;
                    }

                    .grandChild {
                        width: 85%;
                        height: 40px;

                        border: 1px solid #d8d8d8;
                        background-color: #f9f9f9;
                        border-radius: 30px;
                        margin-top: 10px;

                        &::before {
                            content: "";
                            border: 1px solid #e0e0e0;
                            position: absolute;
                            width: 0px;
                            border-bottom-left-radius: 999px;
                            height: 60px;
                            left: -28px;
                            margin-bottom: 59px;
                        }

                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        padding: 10px;

                        p {
                            font-size: 1rem !important;
                        }
                    }
                }
            }
        }
    }
}

p {
    margin: 0;
    padding: 0;
}

.cross {
    width: 20px;
    height: 20px;

    border: 1px solid rgb(255, 0, 0);
    border-radius: 999px;
}

img {
    cursor: pointer !important;
}

.scroll {
    &::-webkit-scrollbar {
        height: 2px; /* Set height to 1px for a horizontal line */
        width: 1px;
    }

    &::-webkit-scrollbar-thumb {
        background: #638fff;
        border-radius: 2px;
        width: 1px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #3f75ff;
        width: 1px;
    }

    &::-webkit-scrollbar-track {
        background: white;
        width: 1px;
    }

    &::-webkit-scrollbar-track:hover {
        background: white;
        width: 1px;
    }
}


.nestedChild{
    color: black;
    margin: 10px;
    border: 1px solid green;
    width: 85%;
    margin-left: 15%;
    border-radius: 50px;
    text-align: left;
    padding-left: 10px;
}

.outputparam{
    display: flex;
    width: 100%;
    justify-content: center;
}

.outputparams {
    margin-left: 25px;

  }
  /* ul.output-params-container2 > .list-item-1:nth-child(2n+1) {
	background-color: #eee;
} */
ul.output-params-container2 li.list-item-1:nth-child(odd) {
    background-color: #eee;
}
.list-item-1 {
    
    opacity: 1;
    /* transform: translateX(-30px);  */
    transition: opacity 1s, transform 2s;
    padding: 7px;
    list-style: none;
  }
  .with-border {
    border-left: 1px solid #385ba2; /* Replace with your desired border color */
  }
 
 
  .outputParamsContainer.scroll1 .list-item-1 {
    opacity: 2;
    transform: translateX(0);
  }

.output-params-container2{
    margin-bottom: 0px;
    margin-top: 0px;
    padding-left: 15px;
}
/* Add this to your CSS */
@keyframes fadeIn {
    from {
      opacity: 0;
      max-height: 0;
    }
    to {
      opacity: 1;
      max-height: 1000px; /* Adjust the max-height value as needed */
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
      max-height: 1000px; /* Adjust the max-height value as needed */
    }
    to {
      opacity: 0;
      max-height: 0;
    }
  }
 
  
  .outputParamsContainer {
    padding-top: 10px;
    animation-duration: 1.5s;
    /* border-left: 2px solid #003399; */
  }
  
  /* Apply the fade-in/fade-out animation to the outputParamsContainer */
  .outputParamsContainer.scroll1 {
    animation-name: fadeIn;
  }
  
  .outputParamsContainer.scroll1.fadeOut {
    animation-name: fadeOut;
  }
  .borderParent{
    border-left: 2px dotted gray;
    box-sizing: border-box;

  }