.spec-form {
	font-family: "Roboto", sans-serif;

	height: calc(100vh - 100px);
	overflow: hidden;
}

.form-cards {
	overflow: auto;
	height: calc(100vh - 100px)
}
