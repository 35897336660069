.autocomplete {
    position: relative;
    width: 100%;
  }
  
  .autocomplete-options {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    list-style-type: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 250px;
    overflow: auto;
  }
  
  .autocomplete-options li {
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .autocomplete-options li:hover {
    background-color: #f5f5f5;
  }
  