:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
}
