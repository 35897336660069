.setup-form-card {
	width: 100%;
	max-width: 800px;
	padding: 15px 20px;
	border-radius: 5px;
	border: 1px solid #f3f3f3;
	margin: 20px 0;
	position: relative;
	width: 100%;
	max-width: 800px;
	padding: 15px 20px;
	border-radius: 5px;
	border: 1px solid #f3f3f3;
	margin: 20px 0;
	position: relative;
}

.main-delete-btn {
	position: absolute;
	top: 20px;
	right: 20px;
	border: none;
	background-color: #fff;
	cursor: pointer;
}
.main-delete-btn:hover {
	transform: scale(1.2);
}
.form-group {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.hidden {
	display: none;
  }

.showmoreBtn{
	margin-bottom: 4px;
	text-align: right;
}
.showmoreBtn>button{
	width: 100px;
	height: 32px;
	/* padding: 4px 30px 4px 28px; */
	justify-content: center;
	align-items: center;
	margin-right: 20px;
	color: #003399;
	border-radius: 5px;
	border: none;
}
.form-group > .input-container {
	/* padding-right: 10px; */
	padding: 0 10px 0 0;
	flex: 1;
	width: 50%;
}
.form-group > .input-container > ::placeholder {
	color: #26282c;

	/* font-family: Acherus Grotesque;  */
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 22px;
}

.form-group > label {
	width: 50%;
	color: #26282c;
	font-family: "Roboto", sans-serif;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 22px;
	margin-top: 10px;
}

.form-heading {
	color: #2f3238;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 26px; /* 144.444% */
}
.form-control {
	border: none !important;
	border-radius: 5px !important;
}
.form-control > option {
	color: #26282c;

	font-family: Acherus Grotesque;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 22px;
}

.parameters-container {
    padding: 8px 0;
}
.outlineborder1{
	margin-bottom: 5px;
    padding: 8px 0 0 8px;
    border-radius: 8px;
    border: 2px solid #EAEAEA;
    background: #FFF;
}
.parameters-container > p {
	color: #6b768a;
	font-family: "Roboto", sans-serif;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 26px;
}
.parameters-container > label {
	color: #6b768a;
	font-family: Varela Round;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 26px; /* 216.667% */
}
.input-container > label {
	color: #6b768a;
	font-family: Varela Round;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 26px; /* 216.667% */
}
.key-container > label {
	color: #26282c;
	font-family: Acherus Grotesque;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px; /* 157.143% */
}
.container-header {
	display: flex;
	width: 100%;
}
.container-header > label {
	width: 33%;
	text-align: left;
	color: #26282c;
	font-family: "Roboto", sans-serif;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 22px;
	margin-top: 10px;
}
.param-btn-container {
	margin: 0;
	padding: 0;
	width: 60%;
	display: flex;
	justify-content: center;
}

.param-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	background-color: #fff;
	padding: 10px 5px;
	cursor: pointer;
	color: #039;
	font-family: "Roboto", sans-serif;
	/* font-size: 14px; */
	/* font-style: normal; */
	/* font-weight: 400;
	line-height: 22px; */
	color: #039;

	/* font-family: Acherus Grotesque; */
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
}

.param-btn > img {
	width: 24px;
	height: 24px;
	margin-right: 10px;
}

.delete-btn {
	width: 20px;
	/* height: 20px; */
	border-radius: 5px;
	color: rgb(62, 62, 62);
	border: none;
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.07);
	padding: 5px;
}

.delete-btn:hover {
	background-color: rgba(255, 99, 71, 0.532);
}

.spec-form {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
}

.form-cards {
	width: 60%;
	height: 100%;
	max-height: 100%;
	overflow: auto;
}

.parameter-header {
	color: #6b768a;
	font-family: Varela Round;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 26px;
	border-bottom: 1px solid #edf3ff;
}

p {
	margin-bottom: 0 !important;
}

.api-description {
	color: #6b768a;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	/* line-height: 26px; */
	color: #6b768a;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	/* line-height: 26px; */
}

.key-container {
	width: 100%;
}
.input-key-container {
	display: flex;
	padding: 5px 0;
	display: flex;
	padding: 5px 0;
}
.input-container {
	gap: 0;
	margin-bottom: 10px;
}

.input-key-container > .tag {
	display: flex;
	width: 134px;
	height: 32px;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	background-color: #edf3ff;
	margin-right: 5px;
	cursor: pointer;
}

.input-key-container > .tag-selected {
	background-color: #039;
	color: white;
}
.output-parameter-flex {
	width: 80%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.output-parameter-flex > select {
	width: 50%;
	display: flex;
	width: 201.15px;
	height: 36px;
	padding: 8px 8.15px 8px 8px;

	justify-content: center;
	align-items: center;
	gap: 88px;
	flex-shrink: 0;
}
.output-parameter-flex > {
	gap: 0;
}
-index > label {
	padding: 0;
	color: #26282c;

	font-family: Acherus Grotesque;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	align-items: center;
	display: flex;
}

.input-container-index > label > img {
	margin-right: 8px;
}

/* toggle switch */
.container {
	margin: 50px auto;
	width: 280px;
	text-align: center;
	background-color: #f2f2f2;
}
.container > .switch {
	display: block;
	margin: 22px auto;
}

.switch {
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: 24px;
	height: 24px;
	padding: 1px;
	border-radius: 3px;
	cursor: pointer;
	transform: rotate(90deg);
	margin-right: 10px;
}

.switch-input {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
}
.switch-label {
	position: relative;
	display: block;
	height: inherit;
	font-size: 10px;
	text-transform: uppercase;
	background: #d5d7db;
	border-radius: inherit;
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12),
		inset 0 0 2px rgba(0, 0, 0, 0.15);
	-webkit-transition: 0.15s ease-out;
	-moz-transition: 0.15s ease-out;
	-o-transition: 0.15s ease-out;
	transition: 0.15s ease-out;
	-webkit-transition-property: opacity background;
	-moz-transition-property: opacity background;
	-o-transition-property: opacity background;
	transition-property: opacity background;
}
.switch-label:before,
.switch-label:after {
	position: absolute;
	top: 50%;
	margin-top: -0.5em;
	line-height: 1;
	-webkit-transition: inherit;
	-moz-transition: inherit;
	-o-transition: inherit;
	transition: inherit;
}
.switch-label:before {
	content: attr(data-off);
	right: 9px;
	color: #aaa;
	text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}
.switch-label:after {
	content: attr(data-on);
	left: 11px;
	color: white;
	text-shadow: 0 1px rgba(0, 0, 0, 0.2);
	opacity: 0;
}
.switch-input:checked ~ .switch-label {
	/* background: rgb(71, 71, 71); */
	background-color: #88b0ff;
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15),
		inset 0 0 3px rgba(0, 0, 0, 0.2);
	/* background: rgb(71, 71, 71); */
	background-color: #88b0ff;
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15),
		inset 0 0 3px rgba(0, 0, 0, 0.2);
}
.switch-input:checked ~ .switch-label:before {
	opacity: 0;
}
.switch-input:checked ~ .switch-label:after {
	opacity: 1;
}

.switch-handle {
	position: absolute;
	top: 3px;
	left: 2px;
	width: 10px;
	height: 19px;
	background: white;
	border-radius: 3px;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
	background-image: -webkit-linear-gradient(top, white 40%, #f0f0f0);
	background-image: -moz-linear-gradient(top, white 40%, #f0f0f0);
	background-image: -o-linear-gradient(top, white 40%, #f0f0f0);
	background-image: linear-gradient(to bottom, white 40%, #f0f0f0);
	-webkit-transition: left 0.15s ease-out;
	-moz-transition: left 0.15s ease-out;
	-o-transition: left 0.15s ease-out;
	transition: left 0.15s ease-out;
	position: absolute;
	top: 3px;
	left: 2px;
	width: 10px;
	height: 19px;
	background: white;
	border-radius: 3px;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
	background-image: -webkit-linear-gradient(top, white 40%, #f0f0f0);
	background-image: -moz-linear-gradient(top, white 40%, #f0f0f0);
	background-image: -o-linear-gradient(top, white 40%, #f0f0f0);
	background-image: linear-gradient(to bottom, white 40%, #f0f0f0);
	-webkit-transition: left 0.15s ease-out;
	-moz-transition: left 0.15s ease-out;
	-o-transition: left 0.15s ease-out;
	transition: left 0.15s ease-out;
}

.switch-input:checked ~ .switch-handle {
	left: 11px;
	box-shadow: -1px 1px 5px rgba(80, 80, 80, 0.23);
}

.switch-green > .switch-input:checked ~ .switch-label {
	background: #4fb845;
}
.parameters-container-btn {
	border: none;
	outline: none;
	padding: 0 10px;
}
/* ================== Custom radio button ================================== */
.input-key-container-radio {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-right: 20px;
	font-size: 13px;
}
/* .input-key-container-radio input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  width: 1em;
  height: 1em;
  border: 2px solid currentColor;
  border-radius: 50%;
  display: grid;
  place-content: center;
}
input[type="radio"]::before {
  content: "";
  width: 0.45em;
  height: 0.45em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #003399;
}

input[type="radio"]:checked::before {
  transform: scale(1);
} */
/* ================== Custom radio button end ================================== */
#analytic-tag,
.param-name {
	background-color: rgb(242 242 242);
	/* background-attachment: fixed; */
}
.param-name {
	padding: 4px 20px;
	border-radius: 5px;
}
.spec-form .form-cards {
	width: 50%;
}
.set-up-form-card-heading-1 {
	font-size: 20;
}
.setup-form-card h2 {
	margin-bottom: 0;
}
input.form-control,textarea.form-control {
	background-color: rgb(242 242 242);
	border-radius: 5px;
}
.parameters-container-btn {
	border: none;
	outline: none;
	padding: 0 10px;
}
/* ================== Custom radio button ================================== */
.input-key-container-radio {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-right: 20px;
	font-size: 13px;
}
/* .input-key-container-radio input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  width: 1em;
  height: 1em;
  border: 2px solid currentColor;
  border-radius: 50%;
  display: grid;
  place-content: center;
}
input[type="radio"]::before {
  content: "";
  width: 0.45em;
  height: 0.45em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #003399;
}

input[type="radio"]:checked::before {
  transform: scale(1);
} */
/* ================== Custom radio button end ================================== */
#analytic-tag,
.param-name {
	background-color: rgb(242 242 242);
	/* background-attachment: fixed; */
}
.param-name {
	padding: 4px 20px;
	border-radius: 5px;
}
.spec-form .form-cards {
	width: 50%;
}
.set-up-form-card-heading-1 {
	font-size: 20px;
	margin-bottom: 10px !important;
}
.setup-form-card h2 {
	margin-bottom: 10px;
}
input.form-control {
	background-color: rgb(242 242 242);
	border-radius: 5px;
}
#customlabel{
padding-bottom: 8px;
}