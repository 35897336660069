@import url("https://fonts.googleapis.com/css2?family=Monofett&family=Poppins&family=Varela+Round&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap");

.integrationitem {
  display: flex;
  height: 35px;
  align-items: center;
  margin-left: 10px;
  border-bottom: 1px solid #dedede;
}
#integrationheading {
  color: #26282c;
  font-family: Varela Round;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

#integrationtext {
  margin-left: 10px;
  font-family: "Roboto", sans-serif;
}

#integrationimg {
  margin-left: 5px;
}
#sidebar-container{z-index: 2;
}
.contract-sidebar {
  width: 128px;
  margin-right: 40px;
  transition: width 1.2s ease;
  -webkit-transition: width 1.2s ease;
  -moz-transition: width 1.2s ease;
  -ms-transition: width 1.2s ease;
  -o-transition: width 1.2s ease;
}

.contract-sidebar .sidepanelDashBoard-top-part {
  margin-bottom: 20px; /* Adjust the value as needed */
  margin-left: 10px;
}

.contract-sidebar .bqHeadingParent {
  margin-bottom: 20px; /* Adjust the value as needed */

  margin-left: 10px;
}


.sidepanelDashBoard {
  z-index: 1;
  width: 20%;
  /*max-width: 20%;
  */transition: width 1s ease;
  max-width: 400px;
  width: 358px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #eee;
  -webkit-transition: width 150ms ease;
  -moz-transition: width 1.2s ease;
  -ms-transition: width 1.2s ease;
  -o-transition: width 1.2s ease;
}
.sidepanelDashBoard{
  position: absolute;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    left: 0;
    top: 66px;
    height: 92vh;
    width: 65px;
    z-index: 3;
    overflow: hidden;
    background: #FFFFFF;
    border-right: 1px solid #EAEAEA;
    /* width: 20%; */
    /*max-width: 20%;
    /* width: 358px; */
    height: 92vh;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #eee;
    
  /* display: flex;
  flex-direction: column;
  border-right: 1px solid #eee;
  -webkit-transition: width 1.2s ease;
  -moz-transition: width 1.2s ease;
  -ms-transition: width 1.2s ease;
  -o-transition: width 1.2s ease; */
}
.sidepanelDashBoard:hover{
  width:310px;
}
.sidepanelDashBoard-top-part{
  flex: 8.8;
  display: flex;
  flex-direction: column;
}
.sidepanelDashBoard-bottom-part{
  flex: 1.2;
}
.sidepanelDashBoard-bottom-part>span{
  display: flex;
  flex-direction: row-reverse;
  margin-right: 0.5em;
  cursor:pointer;
}

.sidepanelDashBoard .searchbar {
  display: none;
}
.displayHidden{
  visibility: hidden;
}
.sidepanelDashBoard .searchbar:hover{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidepanelDashBoard .searchbar img {
  margin-right: 10px;
  cursor: pointer;
  border-bottom: 1px solid #dedede;
  width: 90%;
  height: var(--height-h-10, 40px);

  flex-shrink: 0;

  flex-shrink: 0;
  margin-left: 24px;
  margin-right: 1px;
}

.bqAllApis {
  display: flex;
  flex-direction: column;
}

.sidepanelDashBoard .bqHeadingParent {
  display: flex;
  width: 327px;
  width: 100%;
  margin-left: 0;
  height: 44px;
  flex-shrink: 0;
  align-items: center;
  border-bottom: 1px solid #eee;
}

.sidepanelDashBoard .bqLogoParent {
  width: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
}

.bqLogoParent > img {
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.bqHeadingNameParent {
  width: 65%;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* margin-top: 15px; */
}
.bqHeadingNameParent::after {
  /* color: ; */
}

.platform-set-up-details-1-left > img {
  object-fit: contain;
  height: 100px;
  width: 100px;
  margin-top: 10px !important;
}

.platform-set-up-details-1-left > img[src="/images/git.png"] {
  object-fit: cover;
}

.bqApisNumberParent {
  width: auto;
  display: flex;
  align-items: center;
  border-radius: 20%;
  margin-right: 10px;
}

#bqApisNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20%;
  height: 24px;
  width: 24px;
  background-color: #dff7ff;
}

.sidepanelDashBoard .bqLogsApiHeading {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
  /* min-height: 55px; */
  cursor: pointer;

}

.hidden{
  visibility: hidden;
}


.bqLogsApiLogoParent {
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.bqLogsApiHeadingNameParent {
  width: 85%;
  display: flex;
  align-items: center;
  /* margin-top: 15px; */
  justify-content: flex-start;
  padding: 3%;

}
.bqLogsApiHeadingNameParents{
  width: 85%;
  display: flex;
  align-items: center;
  /* margin-top: 15px; */
  justify-content: flex-start;
  padding: 3%;

margin-left: 3%;
}

.bqLogsApiHeadingNameParents:hover {
  width: 85%;
  display: flex;
  align-items: center;
  /* margin-top: 15px; */
  justify-content: flex-start;
  padding: 3%;
  background-color: lightgrey;

}


.create {
  background-color: white;
  /* padding: 5px; */
  transition: background-color 0.2s ease;
}

.create:active {
  background-color: #cfe2ff;
  /* padding: 5px; */
}

.accordion-body {
  padding: 0px !important;
}

.accordion-button {
  padding: 0px !important;
}
.accordion-button::after {
  margin-left: -5px !important;
  display: none;
}
.accordion-item {
  border: none !important;
}
.my-hr-line {
  width: 309px;
  height: 0px;
  flex-shrink: 0;
  stroke-width: 1px;
  stroke: #eee;
  margin: 0 22.5px 0 32.5px;
  padding: 0;
}
.side-panel-accordion-arrows {
  /* transition: transform 0.3s ease-in-out; */
}
.side-panel-search-input {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.side-panel-search-input > input {
  width: 100%;
  height: 100%;
  margin-left: 24px;
  border: none;
  outline: none;
}

.bqLogsApiHeading-create {
  padding: 3%;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.075);
}
.accordion-button:focus{
  box-shadow: none !important;
}
.accordion-button:not(.collapsed){
  border-radius: 5px 5px 0 0 !important;

}
.tagSearch-Container{
  margin: 0.2em 1.2em;;
}
.tagSearch-Container em{
  color: #dedede;
}
.rti--container{
  --rti-main: #003399ce !important;
}
.rti--input{
  width: 95% !important;
}
.accordion-button:not(.collapsed) {
  background: none !important;
}
.activeApi {
  background-color: #cfe2ff !important;
}
.sidepanelDashBoard #bqArrowParent{
  width: 100%;
    display: flex;
    justify-content: end;
    margin-right: 15px
}
