.WorkflowPreview {
  margin-top: 10px;
  /* margin-left: 40px; */
  /* height: 100vh; */
  border-left: 1px solid rgb(215, 215, 215);
  padding-left: 20px;
  overflow: auto;
}

.WorkflowPreview img {
  max-width: 550px;
  margin: 20px 0;
}

#WorkflowPreview-title {
  text-align: left;
  color: #26282c;
  font-family: Acherus Grotesque;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}
.sub_heading {
  color: #26282c;
  font-family: Acherus Grotesque;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  width: 309px;
}

.main_heading {
  color: #6b768a;
  font-family: Acherus Grotesque;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.schedule_workflow {
  color: var(--Neutral-colours-Grey-900, #2f3238);
  font-family: Acherus Grotesque;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}

.inputStyle {
  color: #2f3238;
  font-family: Acherus Grotesque;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 16px;
}

.block_1,
.block_2 {
  width: 310px;
  height: 36px;
  display: flex;
  margin-top: 8px;
  justify-content: space-between;
}
.input_1 {
  border-radius: 5px;
  border: none;
  background: rgba(107, 118, 138, 0.05);
  width: 147px;
  height: 36px;
  padding: 7px 12px 7px 12px;
  font-family: Acherus Grotesque;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.input_2 {
  border: none;
  border-radius: 5px;
  background: rgba(107, 118, 138, 0.05);
  color: #9ca7ba;
  font-family: Acherus Grotesque;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  height: 36px;
  width: 147px;
}

.on {
  color: #2f3238;
  font-family: Acherus Grotesque;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.input_3 {
  border-radius: 5px;
  border: none;
  background: rgba(107, 118, 138, 0.05);
  width: 147px;
  height: 36px;
}

.input_4 {
  border-radius: 5px;
  border: none;
  background: rgba(107, 118, 138, 0.05);
  width: 147px;
  height: 36px;
}

.block_3 {
  width: 310px;
  height: 36px;
  display: flex;
  margin-top: 8px;
  justify-content: space-between;
  color: #2f3238;
  font-family: Acherus Grotesque;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
}

.block3_input1 {
  width: 98px;
  height: 36px;
  padding: 7px 8px 7px 12px;
  gap: 40px;
  flex-shrink: 0;
  border-radius: 5px;
  background: rgba(107, 118, 138, 0.05);
  border: none;
}
