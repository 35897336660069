@import url("https://fonts.googleapis.com/css2?family=Monofett&family=Poppins&family=Varela+Round&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");
.Navbar {
  background: #26282c;
  padding-top: 10px;
  width: 100vw;
  height: 60px;
  box-sizing: border-box;
  display: flex;
  /* position: fixed;
  z-index: 2; */
  color: white;
  justify-content: space-between;
  align-items: center;
  /* padding-top: 8px; */
  padding-left: 10px;
  padding-bottom: 10px;
}
.nav-left {
  display: flex;
  gap: 25px;
  align-items: center;
  height: 100%;
}
.nav-right {
  min-width: 140px;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-right: 12px;
}
.navitem {
  height: 100%;
  display: flex;
  align-items: center;
  font-family: "Monofett", monospace;
  font-family: "Poppins", sans-serif;
  font-family: "Varela Round", sans-serif;
  font-size: 16px;
  cursor: pointer;
}
.navitemselect {
  background-color: white;
  color: black;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: none;
  padding: 5px;
}
.nav-select {
  background-color: transparent;
  color: white;
  height: 20px;
  outline: none;
  border: none;
}
.logotext {
  font-family: "Monofett", monospace;
  font-family: "Poppins", sans-serif;
  font-family: "Varela Round", sans-serif;
  font-size: 24px;
}
.username {
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  position: relative;
  top: 4px;
}
.navbar-cogwheel {
  cursor: pointer;
}
#sl-logo {
  max-width: 40px;
  margin-left: 5px;
  /* vertical-align: middle; */
  padding-bottom: 6px;
}
.nav-middle {
  position: relative;
  right: 2.8vw;
}
