.integration-setup {
  width: 100%;
  /* height: 100vh; */
  font-family: "Roboto", sans-serif;
  margin-top: 20px;
  overflow: auto;
  height: calc(100vh - 130px);
}
.integration-setup-container{

}
.integration-setup-form {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-left: 2em;
  gap: 0.75em;
}

.integration-setup-label,
.integration-setup-radio-heading {
  color: #26282c;
  font-family: Acherus Grotesque;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.integration-setup-input {
  border: none;
  margin-left: 1em;
  color: #2f3238;
  font-family: Acherus Grotesque;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 1em;
  border-radius: 5px;
  background: rgba(107, 118, 138, 0.05);
}

.integration-setup-input.clickable-link {
  text-decoration: underline;
  color: #0052f5;
  cursor: pointer;
}

.visitedLink {
  color: darkblue !important;
}

.integration-setup-radio-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 1em;
}

.integration-setup-radio {
  margin-right: 4em;
}

.integration-setup-radio-label {
  margin-left: 0.5em;
  color: #9ca7ba;
}

.integration-setup-input-placeholder::placeholder {
  border: none;
  color: #9ca7ba;
  font-family: Varela Round;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.integration-setup-input-placeholder {
  margin-left: 1em;
}

.integration-setup input {
  padding: 5px;
}

.submitBtn {
  width: 80px;
  margin-top: 20px;
  margin-left: 50%;
  border: none;
  padding: 5px;
  font-weight: 500;
  border-radius: 4px;
}

.submitBtn:hover {
  background-color: lightgray;
}
