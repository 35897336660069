.container {
    max-width: 1200px; /* Set a maximum width for the container */
    margin: 0 auto;
    padding: 20px;
    background-color: #fff; /* Add a background color to the container */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
    border-radius: 8px; /* Add rounded corners */
  }
  
  .table-container {
    /* overflow-x: auto; */
    /* max-width: 100%; */
    width: 100%;
    /* height: 335px; */
  }
  
  .json-table {
    width: 100%;
    border-collapse: collapse;
    /* margin-bottom: 17px; */
    /* table-layout: fixed; */
  }
  
  .json-table th, .json-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    /* min-width: 100px; 
  
    max-width: 300px; */
    /* overflow: hidden; */
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: break-word; /* Allow word wrapping if needed */
    transition: background-color 0.3s;
    box-sizing: border-box;
    position: relative;
  }
  /* .json-table th, .json-table td:hover::after {
    content: attr(data-content); 
    position: absolute;
    top:-10px;
    left: -5px;
    background-color: #f0f0f0; 
    color: #333;
    z-index: 10;
    white-space: nowrap;
    
    text-overflow: ellipsis;
    word-wrap: break-word;
   
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    font-size: 14px;
  } */
  .tooltip {
    position: absolute;
    left: 5px;
    top: 30px;
    background-color: #f9f9f9;
    color: #333;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
    z-index: 10;
    /* width: 200px; */
    display: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  /* tooltip {
    position: absolute;
    background-color: #f9f9f9;
    color: #333;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
    z-index: 10;
    width: 200px;
    display: none;
    white-space: normal;
    overflow: visible;
    text-overflow: initial;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  } */
  
  .cellvalue{
    overflow: hidden;
  width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: break-word; /* Allow word wrapping if needed */
    /* transition: background-color 0.3s; */
    box-sizing: border-box;
  }
  .tooltipcontainer{
    position: absolute;
  }
  .tooltipcontainer:hover .tooltip{
    display: block;
  }
  
  .json-table th:hover .tooltip,
  .json-table td:hover .tooltip {
    display: block;
  }
  .json-table th {
    background-color: #f2f2f2; /* Add a background color to header cells if needed */
  }
  
  /* Add the following CSS to dynamically set column widths */
  .json-table {
    display: table;
    width: 100%;
    /* table-layout: fixed; */
  }
  
  .json-table th, .json-table td {
    display: table-cell;
    box-sizing: border-box;
    position: relative;
  }
  
  .json-table tbody tr:hover {
    background-color: #f0f0f0; /* Add a background color on hover */
  }
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .pagination button {
    background-color: #003399;
    color: white;
    border: 1px solid #003399;
    padding: 4px 9px;
    cursor: pointer;
    margin: 5px;
    border-radius: 4px; /* Add rounded corners */
  }
  
  .pagination button:hover {
    background-color: #003399;
  }
  
  .pagination button:disabled {
    background-color: #ddd;
    color: #666;
    cursor: not-allowed;
  }
  
  @media (max-width: 600px) {
    .pagination {
      flex-direction: column;
      align-items: center;
    }
  
    .pagination button {
      margin: 5px 0;
    }
  }

  #loaderContainer1 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
  }