.custom-stepper {
    display: flex;
    align-items: center;
  }
  
  .step-container {
    display: flex;
    align-items: center;
  }
  
  .step {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  
  .step-circle {
    width: 120px; /* Adjust the circle size as needed */
    height: 120px; /* Adjust the circle size as needed */
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff; /* Circle background color */
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2); /* Optional: Add shadow */
  }
  
  .step-image {
    width: 80%; /* Make sure the image covers the entire circle */
    height: auto;
    object-fit: cover;
  }
  
  .step-title {
    font-size: 14px;
    text-align: center;
    margin-top: 5px;
  }
  
  .step-line {
    height: 3px; /* Line height */
    width: 110px; /* Line length */
    background-color: #4a62d9; /* Line color */
    margin-bottom: 30px;
    /* margin-left: 10px; */
    /* margin-right: 10px; */
  }
  