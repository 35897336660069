.tooltip-container {
    position: relative;
    display: inline-block;
  }
  
  .tooltip-target {
    cursor: pointer;
  }
  
  .tooltip {
    visibility: hidden;
    background-color: white;
    /* color: white; */
    border-radius: 6px;
    padding: 5px 5px 5px 15px;
    position: absolute;
    z-index: 2;
    box-shadow: 2px 2px 25px #aaa;
    top: 50%;
    left: calc(100% + 15px);
    transform: translateY(-50%);
    animation: fadeInRight 0.5s ease forwards;
  }
  @keyframes fadeInRight {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .tooltip::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -15px;
    border-width: 15px;
    border-style: solid;
    border-color: transparent white transparent transparent;
    box-shadow: 0px 0px 0px #aaa;
  }
  
   
  .tooltip-container.show-tooltip .tooltip {
    visibility: visible;
  }